import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PartnersRow from "../components/Partners";
import ServicesRow from "../components/ServicesRow";
import AboutRow from "../components/AboutRow";
import HomeRow from "../components/HomeRow";
import Seo from "../components/Seo";

export default function Home() {
  return (
    <>
      <Seo title="Home" description="City Logistics & Courier Home Page" />
      <Navbar />
      <HomeRow />
      <ServicesRow hideall={true} />
      <AboutRow />
      <PartnersRow hideall={true} />
      <Footer />
    </>
  );
}
