import React from "react";
import { Link } from "react-router-dom";
import Logo from "../assets/svg/city.png";

const currentYear = new Date().getFullYear();

export default function Footer() {
    return (
        <div className="bg-[#09244d] mt-10">
            <div className="flex flex-col w-xl h-xl lg:mx-44 py-12 divide-y-2 gap-y-10 md:flex md:mx-10 sm:mx-10 xs:mx-10">

                <div className="lg:flex lg:flex-row justify-between py-8 md:flex md:flex-col sm:flex-col">
                    <p className="text-white text-4xl md:text-center sm:text-center xs:text-center">City Lojistik İle <br /> Beklentilerinizi Yükseltin</p>

                    <div className="lg:flex gap-10 md:flex md:pt-10 sm:pt-10 xs:pt-10">
                        <Link to="/beCarrier">
                            <p className="text-center text-sm font-medium text-white rounded-md border-2 border-white py-5 px-20 md:mx-14 sm:mb-10 xs:mb-10">
                                Taşıyıcı Ol
                            </p>
                        </Link>
                        <Link to="/beCustomer">
                            <p className="text-center text-sm font-medium text-[#09244d] bg-white rounded-md border-2 border-white py-5 px-20 md:mx-14">
                                Müşterimiz Ol
                            </p>
                        </Link>
                    </div>
                </div>

                <div className="flex justify-between py-16 flex-col md:flex-row">
                    <img src={Logo} alt="" className="w-28 h-20 mt-3 mb-10" />
                    <div className="flex gap-10 md:flex">
                        <div className="flex flex-col md:flex-row gap-3">
                            <ol className="lg:px-5">
                                <Link to="/"><li className="text-white text-sm pb-2">Anasayfa</li></Link>
                                <Link to="/about"><li className="text-white text-sm py-2">Hakkımızda</li></Link>
                                <Link to="/suggestionAndComplaint"><li className="text-white text-sm py-2">Öneri ve Şikayet</li></Link>
                                <Link to="/informationSecurityPolicy"><li className="text-white text-sm pt-2">Bilgi Güvenliği Politikası</li></Link>
                            </ol>
                            <ol className="lg:px-5">
                                <Link to="/customers"><li className="text-white text-sm pb-2">Müşterilerimiz</li></Link>
                                <Link to="/SSS"><li className="text-white text-sm py-2">Sıkça Sorulan Sorular</li></Link>
                                <Link to="/technologyAndRDCenter"><li className="text-white text-sm pt-2">Teknoloji ve Arge Merkezi</li></Link>
                            </ol>
                            <ol className="lg:px-3">
                                <Link to="/KVKK"><li className="text-white text-sm pb-2">KVKK Aydınlatma Metni</li></Link>
                                <Link to="/applicationFormToDataController"><li className="text-white text-sm py-2">Veri Sorumlusuna Başvuru Formu</li></Link>
                                <Link to="/informationTextForCustomers"><li className="text-white text-sm pb-2">Müşterilere İlişkin Aydınlatma Metni</li></Link>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="flex justify-between py-8 md:flex">
                    <p className="text-white text-xs">Copyright {currentYear} City Lojsitik</p>

                    <div className="flex gap-10">

                        <a href="https://www.facebook.com/citylojistik" target="_blank" rel="noreferrer">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http:www.w3.org/2000/svg"
                                className="w-6 h-6"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M22 6.86V17.14C22 19.82 19.82 22 17.14 22H15.79V15.41C15.79 15.13 16.02 14.91 16.29 14.91H17.76C18.01 14.91 18.22 14.72 18.25 14.47L18.48 12.75C18.52 12.45 18.29 12.18 17.99 12.18H16.29C16.02 12.18 15.79 11.96 15.79 11.68V10.46C15.79 10.06 15.87 9.74 16.03 9.5C16.23 9.26 16.59 9.14 17.11 9.14H18.06C18.33 9.14 18.56 8.91 18.56 8.64V7.18C18.56 6.92 18.37 6.7 18.12 6.68C17.64 6.63 17.08 6.61 16.47 6.61C15.39 6.61 14.55 6.93 13.91 7.57C13.27 8.21 12.99 9.06 12.99 10.18V11.68C12.99 11.96 12.76 12.18 12.49 12.18H11.04C10.77 12.18 10.54 12.41 10.54 12.68V14.41C10.54 14.68 10.77 14.91 11.04 14.91H12.49C12.76 14.91 12.99 15.13 12.99 15.41V22H6.86C4.18 22 2 19.82 2 17.14V6.86C2 4.18 4.18 2 6.86 2H17.14C19.82 2 22 4.18 22 6.86Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                        <a href="https://twitter.com/city_logistics" target="_blank" rel="noreferrer">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http:www.w3.org/2000/svg"
                                className="w-6 h-6"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M17.14 2H6.86C4.18 2 2 4.18 2 6.86V17.14C2 19.82 4.18 22 6.86 22H17.14C19.82 22 22 19.82 22 17.14V6.86C22 4.18 19.82 2 17.14 2ZM16.79 8.87C17.41 11.31 16.72 13.87 14.96 15.44C12.7 17.46 9.03 17.55 6.34 15.45C6.54 15.48 6.72001 15.49 6.88 15.48C8.15 15.43 9.01999 14.42 9.28999 14.08C8.81999 13.67 8.09 12.95 7.58 11.84C6.86 10.3 6.93 8.85 7.05 8.03C7.08 7.87 7.27999 7.81 7.39999 7.94C7.76999 8.36 8.37 8.93 9.25 9.34C10.2 9.78 11.06 9.86 11.55 9.86C11.52 8.67 12.23 7.6 13.3 7.19C14.24 6.82 15.33 7.03 16.1 7.73C16.62 7.71 17.14 7.69999 17.66 7.67999C17.37 8.07999 17.08 8.47 16.79 8.87Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                        <a href="https://www.linkedin.com/company/city-logistics-&-courier/" target="_blank" rel="noreferrer">
                            <svg
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http:www.w3.org/2000/svg"
                                className="w-6 h-6"
                                preserveAspectRatio="none"
                            >
                                <path
                                    d="M17.14 2H6.85999C4.17999 2 2 4.18 2 6.86V17.14C2 19.82 4.17999 22 6.85999 22H17.14C19.82 22 22 19.82 22 17.14V6.86C22 4.18 19.82 2 17.14 2ZM8.92001 9.17C8.36001 9.17 7.89001 8.71 7.89001 8.15C7.89001 7.58 8.35 7.12 8.91 7.12H8.92001C9.49001 7.12 9.95001 7.58 9.95001 8.15C9.95001 8.71 9.49001 9.17 8.92001 9.17ZM9.64999 10.72V15.85C9.64999 16.27 9.31999 16.6 8.89999 16.6C8.48999 16.6 8.14999 16.27 8.14999 15.85V10.72C8.14999 10.3 8.48999 9.97 8.89999 9.97C9.31999 9.97 9.64999 10.3 9.64999 10.72ZM15.85 15.85C15.85 16.27 15.51 16.6 15.1 16.6C14.68 16.6 14.35 16.27 14.35 15.85V13.51C14.35 12.88 14.22 12.77 14.12 12.68C14 12.57 13.79 12.5 13.53 12.49C13.32 12.5 13.11 12.57 12.99 12.68C12.89 12.77 12.76 12.88 12.76 13.51V15.85C12.76 16.27 12.43 16.6 12.01 16.6C11.6 16.6 11.26 16.27 11.26 15.85V13.51C11.26 12.6 11.48 12.02 11.99 11.56C12.38 11.21 12.91 11.02 13.53 10.99C14.2 11.02 14.73 11.21 15.13 11.56C15.63 12.02 15.85 12.6 15.85 13.51V15.85Z"
                                    fill="white"
                                />
                            </svg>
                        </a>
                    </div>
                </div>

            </div>
        </div>

    );
}