import React from "react";
import PartnerCard from "../components/PartnerCard"
import PartnerZaim from "../assets/svg/partnerZaimCompany.png";

interface PartnersProps {
    hideall?: boolean;
}

export default function PartnersRow(props: PartnersProps) {
    const { hideall } = props;
  return (
    <>
      <div className="bg-[#F1F1F1] m-5 sm:m-20 p-10 sm:p-32 flex gap-36 flex-col xl:flex-row">
        <div className="basis-1/2">
          <p className="font-medium lg:text-5xl text-[#09244D] md:text-3xl sm:text-xl xs:text-xl">
            City Lojistik
            <span className="inline-block lg:w-20 h-1 lg:ml-5 lg:mt-0 pt-0 mb-3 top-0 rounded-lg bg-[#f4bc2f] md:w-10 md:ml-5 sm:w-5 sm:mt-5"></span>
          </p>
          <p className="font-medium lg:text-7xl text-[#09244D] pt-3 md:text-5xl sm:text-3xl xs:text-3xl">Partnerlerimiz</p>
          <p className="text-[#09244D] pt-9">City Logistics & Courier Taşımacılık Hizmetleri A.Ş. Ağustos 1999 yılında kurularak Lojistik faaliyetlerine başlamıştır.</p>
          <p className="text-[#09244D] pt-6">O dönemde, hızla gelişen müşteri ihtiyaçları paralelinde depolama, elleçleme, Şehiriçi ve şehirdışı, Gece/Gündüz Rut bazlı teslimatlar, Komple ve Parsiyel Karayolu nakliye hizmetleri, Masadan masaya hızlı kara ve hayayolu taşımacılık hizmetleri dahilinde hizmet verilmeye başlanmıştır.</p>
        </div>
        <div className="basis-1/2 flex flex-col gap-5">
          <div className="flex flex-col sm:flex-row gap-5">
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
          </div>
          <div className="flex flex-col sm:flex-row gap-5">
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
          </div>
        </div>
      </div>
      {
        hideall ? null : (
        <div className="m-5 sm:m-20 p-10 sm:p-32 flex gap-36 flex-col xl:flex-row justify-center">
            <div className="flex flex-col sm:flex-row gap-5">
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
            <PartnerCard image={PartnerZaim} />
            </div>
        </div>
        )}
    </>
  );
}
