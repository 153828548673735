import React from "react";
import BigTruck from "../assets/svg/bigTruck.png"
import MiniTruck from "../assets/svg/miniTruck.png"

export default function HomeRow() {
  return (
    <>
      {/* <div className="w-screen h-screen">
        <div className="w-3xl h-screen">
          <div className="w-[933px] h-[850px] absolute left-[926.5px] top-[149.5px] rounded-[10px] bg-[#09244d]" />
          <p className="w-[356px] h-[41px] absolute left-[1235px] top-[270px] text-4xl font-bold text-left text-white">
            Gönderi Sorgulama
          </p>
          <p className="w-[470px] h-[92px] absolute left-[1235px] top-[351px] text-sm font-medium text-left text-white">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra quis habitasse nunc diam
            feugiat orci quis ac in. Pellentesque pellentesque faucibus imperdiet massa ornare leo. Tellus
            facilisis vitae rhoncus, risus, scelerisque enim urna. Vel venenatis magna magna turpis.Lorem
            ipsum dolor sit amet, consectetur adipiscing elit. Viverra quis habitasse nunc diam feugiat
            orci quis ac in.
          </p>
          <div className="w-[469px] h-[59px]">
            <div className="w-[469px] h-[59px] absolute left-[1234.5px] top-[487.5px] rounded-[10px] bg-white" />
            <p className="absolute left-[1250px] top-[508px] text-base font-medium text-left text-[#c3c3c3]">
              Gönderi Takip Numaranızı Giriniz...
            </p>
            <div className="flex justify-center items-center w-[104px] h-[49px] absolute left-[1595px] top-[493px] gap-2.5 px-[25px] py-2.5 rounded-[10px] bg-[#09244d]">
              <p className="flex-grow-0 flex-shrink-0 text-sm font-medium text-left text-white">
                Sorgula
              </p>
            </div>
          </div>
          <img
            src={BigTruck}
            className="w-[990px] h-[750px] absolute left-[159.5px] top-[199.5px] rounded-[10px] object-cover" alt="bigTruck"
          />
          <svg
            width={50}
            height={10}
            viewBox="0 0 50 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-[50px] h-2.5"
            preserveAspectRatio="none"
          >
            <circle cx={5} cy={5} r={5} fill="white" />
            <circle cx={25} cy={5} r={5} fill="white" fill-opacity="0.5" />
            <circle cx={45} cy={5} r={5} fill="white" fill-opacity="0.5" />
          </svg>
        </div>
        <div className="w-xl h-sm">
          <div className="w-[500px] h-[140px] absolute left-[59.5px] top-[549.5px] rounded-[10px] bg-[#09244d]" />
          <p className="absolute left-[117px] top-[592px] text-5xl font-bold text-left text-white">
            Size Özel Gelecek
          </p>
        </div>
      </div> */}

<div className="container flex w-screen h-screen mt-0 pt-0 top-0">
        <div className="flex items-center mt-0 pt-0">
          <div className="absolute w-3/12 h-fit rounded-lg bg-[#09244d] py-6 px-5 ml-20 z-40">
            <p className="text-5xl font-bold text-left text-white">Size Özel Gelecek</p>
          </div>
          <div className="absolute left-48 w-6/12 rounded-[10px] object-cover z-30">
            <img
              src={BigTruck} alt="bigTruck"
            />
          </div>
          <div className="absolute w-1/2 h-5/6 bg-[#09244d] rounded-lg z-20 right-0 pl-56 mr-5 flex flex-col justify-center">
            <div className="absolute top-40 pr-20">
              <p className="w-[356px] text-4xl font-bold text-left text-white">
                Gönderi Sorgulama
              </p>
              <p className="text-sm font-bold text-left text-white mt-12">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra quis habitasse nunc diam feugiat orci quis ac in. Pellentesque pellentesque faucibus imperdiet massa ornare leo. Tellus facilisis vitae rhoncus, risus, scelerisque enim urna. Vel venenatis magna magna turpis.Lorem ipsum dolor sit amet, consectetur adipiscing elit. Viverra quis habitasse nunc diam feugiat orci quis ac in.
              </p>
            </div>
            <div className="mt-8 bg-white w-5/6 px-5 py-3 rounded-lg">
              <form className="flex flex-row items-center">
                <input type="text" className="w-full h-full m-0" placeholder="Gönderi Takip Numaranızı Giriniz..." />
                <button className="bg-[#09244d] text-white px-6 py-3 rounded-lg m-0">Sorgula</button>
              </form>
            </div>
            <div className="absolute bottom-20 right-36 w-1/2">
              <img src={MiniTruck} alt="" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
