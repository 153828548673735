import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import PartnersRow from "../components/Partners";
import Seo from "../components/Seo";

export default function Partners() {
  return (
    <>
      <Seo title="Partners" description="City Logistics & Courier Partners Page" />
      <Navbar />
      <PartnersRow />
      <Footer />
    </>
  );
}
