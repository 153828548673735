import React from "react";
import { Link } from "react-router-dom";

interface SubMenuRowProps {
  title: string;
  url: string;
}

export default function SubMenuRow(props: SubMenuRowProps) {
  const { title, url } = props;
  return (
    <Link
      to={url}
      className="flex  h-[52px] mb-4 flex-col border-b border-[#ffffff] border-solid border-opacity-50]"
    >
      <div className={"flex flex-1 flex-row justify-between"}>
        <p className="text-[27px] font-medium text-left text-white">{title}</p>

        <div className="h-[52px] flex flex-1 self-end justify-end">
          <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6 h-6"
            preserveAspectRatio="none"
          >
            <path
              d="M18.1402 10.82L7.08014 5.15C6.20014 4.7 5.14014 5.33999 5.14014 6.32999V17.67C5.14014 18.66 6.19014 19.31 7.08014 18.85L18.1402 13.18C19.1002 12.69 19.1002 11.31 18.1402 10.82Z"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </div>
    </Link>
  );
}
