import React from "react";
import { Helmet } from "react-helmet-async";

export default function Seo(
    props: {
        title: string;
        description: string;
    }
) {
    const { title, description } = props;
  return (
    <Helmet>
      <title>City Logistics & Courier {title} Page</title>
      <meta name="description" content={description} />
      <meta name="author" content="City Logistics & Courier" />
      <meta name="keywords" content="Logistics, Courier" />
    </Helmet>
  );
}