import React from "react";
import MenuBackground from "../assets/svg/menu.jpeg";
import SubMenuRow from "./SubMenuRow";
import classNames from "classnames";
import Logo from "../assets/svg/whiteLogo.svg";

interface MenuProps {
  toggleMenu: boolean;
  setToggleMenu: (toggleMenu: boolean) => void;
}

export default function Menu(props: MenuProps) {
  const { toggleMenu, setToggleMenu } = props;
  return (
    <div
      className={classNames(
        "flex-1 fixed inset-0 bg-white z-50 flex  transition-[transform,opacity] overflow-hidden no-scrollbar",
        {
          "translate-y-[-100vh]": !toggleMenu,
          "opacity-0": !toggleMenu,
          "no-scrollbar": !toggleMenu,
          "overflow-hidden": !toggleMenu,
        }
      )}
    >
      <svg
        width={70}
        height={70}
        viewBox="0 0 70 70"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="w-[50px] h-[50px] absolute top-[64px] right-[64px] z-30"
        preserveAspectRatio="none"
        onClick={() => setToggleMenu(false)}
      >
        <path
          d="M35.0002 61.9791C20.096 61.9791 8.021 49.9041 8.021 35C8.021 20.0958 20.096 8.02079 35.0002 8.02079C49.9043 8.02079 61.9793 20.0958 61.9793 35C61.9793 49.9041 49.9043 61.9791 35.0002 61.9791Z"
          stroke="white"
          stroke-width={3}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M40.5139 29.3998L29.4888 40.5998"
          stroke="white"
          stroke-width={3}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M29.4888 29.3998L40.5139 40.5998"
          stroke="white"
          stroke-width={3}
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <img
        src={MenuBackground}
        className={"inset-0 fixed object-fill overflow-hidden h-[100vh] z-10"}
        alt="City Lojistik"
      />

      <div
        className={
          "flex flex-1 gap-[64px] p-24 lg:flex-row flex-col z-20 overflow-hidden no-scrollbar"
        }
      >
        <div className="flex flex-1 flex-col">
          <SubMenuRow title="Anasayfa" url="/" />
          <SubMenuRow title="Hizmetlerimiz" url="/services" />
          <SubMenuRow title="Hakkımızda" url="/about" />
          <SubMenuRow title="Partnerlerimiz" url="/partners" />
          <SubMenuRow title="İletişim" url="/contact" />
          <div className="h-[32px] flex-1" />
        </div>

        <div className="flex flex-1 flex-col gap-4 pb-0 lg:pb-24">
          <div className="flex justify-start">
            <img
              src={Logo}
              className={"md:h-[150px] h-[100px] w-auto object-fit"}
              alt="City Lojistik"
            />
          </div>
          <p className=" text-4xl font-medium text-left text-[#f4bc2f]">
            +90 0850 259 24 89
          </p>
          <p className=" text-lg font-medium text-left text-white">
            <span className="text-lg font-medium text-left text-white">
              City Logistics Courier Taşımacılık Hizmetleri Ticaret A.Ş.{" "}
            </span>
            <br />
            <span className="text-lg font-medium text-left text-white">
              Tepeören Mah. Karadağ Cad. Stüdyo Milenium Park No:18-6/3{" "}
            </span>
            <br />
            <span className="text-lg font-medium text-left text-white">
              34959 Tuzla – İSTANBUL
            </span>
          </p>
          <p className="text-lg font-medium text-left text-white">
            info@citylojistik.com
          </p>
        </div>
      </div>
    </div>
  );
}
