import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ServicesRow from "../components/ServicesRow";
import Seo from "../components/Seo";

export default function Services() {
  return (
    <>
      <Seo title="Services" description="City Logistics & Courier Services Page" />
      <Navbar />
      <ServicesRow />
      <Footer />
      </>
  );
}
