import React from "react";

export default function TransparentCard(
    props: {
        image: string;
        title1: string;
        title2: string;
        p1: string;
        p2: string;
    }
) {
    const { image, title1, title2, p1, p2 } = props;
    return (
        <>
            <div className="max-w-2xs">
                <img className="w-18 h-18 mb-10 mt-5" src={image} alt="" />
                { title2 ? (<p className="mb-3 font-medium text-sm text-white">{title1} <br /> {title2}</p>) : (<p className="mb-2 text-lg font-bold tracking-tight text-[#f4bc2f]">{title1}</p>)}
                <p className="mb-3 font-medium text-sm text-white">{p1}</p>
                { p2 ?? (<p className="mb-3 font-medium text-sm text-white">{p2}</p>) }
            </div>
        </>
    );
}