import React from "react";

export default function Card(
    props: {
        image: string;
        title1: string;
        title2: string;
        p1: string;
        p2: string;
    }
) {
    const { image, title1, title2, p1, p2 } = props;
    return (
        <>
            <div className="max-w-2xs p-6 bg-white border border-gray-200 rounded-lg shadow">
                <img className="w-18 h-18 mb-10 mt-5" src={image} alt="" />
                <p className="mb-2 text-lg font-bold tracking-tight text-[#f4bc2f]">{title1} <br /> {title2}</p>
                <p className="mb-3 font-medium text-sm text-[#09244d]">{p1}</p>
                { p2 ?? (<p className="mb-3 font-medium text-sm text-[#09244d]">{p2}</p>) }
            </div>
        </>
    );
}