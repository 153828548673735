import React from "react";

export default function ContactCard(
    props: {
        image: string;
        tel?: string;
        mail?: string;
        title?: string;
        p1: string;
    }
) {
    const { image, tel, mail, title, p1 } = props;
    return (
        <div className="flex flex-col lg:flex-row pl-3 pt-3 pb-5 m-2 md:h-44 h-fit rounded-2xl border-2 border-[#D4D4D4] w-fit md:max-w-xl">
            <div className="mt-5 lg:w-60">
                <img src={image} alt=""/>
            </div>
            <div className="flex flex-col pl-10 gap-5">
                <div>
                    <a href="tel:{tel}" className="text-[#F4BC2F] text-3xl">{tel}</a>
                    <a href="mailto:{mail}" className="text-[#F4BC2F] text-3xl">{mail}</a>
                    <p className="text-[#F4BC2F] text-3xl">{title}</p>
                </div>
                <div className="text-[#09244D]">
                    {p1}
                </div>
            </div>
        </div>
    );
}