import React from "react";
import Logo from "../assets/svg/city.png";
import { Link } from "react-router-dom";
import Menu from "./Menu";

export default function Navbar() {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  return (
    <>
      <header className="flex flex-1">
        <nav className="flex flex-1 h-[85px] mt-[25px] lg:px-[64px] px-[24px] justify-around">
          <div
            className={`hidden xl:flex flex-1 gap-4 justify-start items-center self-center ${!toggleMenu ? "h-0" : "h-full"
              }`}
          >
            <Link
              to={"/"}
              className="text-base font-bold text-left text-[#09244d]"
            >
              Anasayfa{" "}
            </Link>
            <Link
              to={"/services"}
              className=" text-base font-bold text-left text-[#09244d]"
            >
              Hizmetlerimiz
            </Link>
            <Link
              to={"/about"}
              className="text-base font-bold text-left text-[#09244d]"
            >
              Hakkımızda
            </Link>
            <Link
              to={"/partners"}
              className=" text-base font-bold text-left text-[#09244d]"
            >
              Partnerlerimiz
            </Link>
            <Link
              to={"/contact"}
              className="atext-base font-bold text-left text-[#09244d]"
            >
              İletişim
            </Link>
          </div>
          <Link to={"/"} className="flex flex-1 justify-start items-center">
            <img
              src={Logo}
              className={"md:h-[85px] h-[45px]  object-cover"}
              alt="City Lojistik"
            />
          </Link>
          <div
            className={
              "flex flex-row items-center gap-8 h-[85px] justify-center"
            }
          >
            <div className="flex justify-center items-center gap-2.5 px-[25px] py-2.5 rounded-[10px] bg-[#09244d]">
              <p className=" text-sm font-medium text-left text-white">
                Gönderi Takibi
              </p>
            </div>
            <button type="button" onClick={() => setToggleMenu(!toggleMenu)}>
              <svg
                width={35}
                height={18}
                viewBox="0 0 35 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="w-[33px] h-4"
                preserveAspectRatio="none"
              >
                <path
                  d="M18 17L1 17"
                  stroke="#09244D"
                  stroke-width={2}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34 9L1 9"
                  stroke="#09244D"
                  stroke-width={2}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M34 1L1 1"
                  stroke="#09244D"
                  stroke-width={2}
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </nav>
      </header>
      <Menu toggleMenu={toggleMenu} setToggleMenu={setToggleMenu} />
    </>
  );
}