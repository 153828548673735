import React from "react";

export default function Card(
    props: {
        image: string;
    }
) {
    const { image } = props;
    return (
        <>
            <div className="flex justify-center items-center max-w-2xs px-2 md:px-6 bg-white border border-gray-200 rounded-lg shadow">
                <img className="object-fit mb-10 mt-5" src={image} alt="" />
            </div>
        </>
    );
}