import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import AboutRow from "../components/AboutRow";
import Seo from "../components/Seo";

export default function About() {
  return (
    <>
      <Seo title="About" description="City Logistics & Courier About Page"/>
      <Navbar />
      <AboutRow />
      <Footer />
    </>
  );
}
