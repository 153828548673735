import React from "react";
import Card from "../components/Card";
import Buildings from "../assets/svg/buildings.png";
import Piston from "../assets/svg/piston.png";
import GasolineBarrel from "../assets/svg/gasolineBarrel.png";
import Man from "../assets/svg/man.png";

interface ServicesProps {
  hideall?: boolean;
}

export default function ServicesRow(props: ServicesProps) {
  const { hideall } = props;
  return (
    <>
      <div className="flex justify-center">
        <div className="max-h-fit w-11/12 sm:py-10 bg-[#f1f1f1] lg:m-8 rounded-xl lg:py-10 lg:pl-20 px-5">
          <div className="">
            <p className="font-medium lg:text-3xl text-[#09244d] md:text-xl sm:text-lg">
              Lojistik
              <span className="inline-block lg:w-20 h-1 lg:ml-5 lg:mt-0 pt-0 mb-3 top-0 rounded-lg bg-[#f4bc2f] md:w-10 md:ml-5 sm:w-5 sm:mt-5"></span>
            </p>
            <p className="font-medium lg:text-7xl text-[#09244d] pt-3 md:text-5xl sm:text-3xl xs:text-xl">Hizmetlerimiz</p>
          </div>
          {
            hideall ? null : (
              <div className="flex flex-row-reverse lg:absolute w-fit right-20 bottom-48 m-0 p-0 lg:pr-20">
                <img src={Man} alt="" className="object-none lg:object-right object-center m-0 p-0" />
              </div>
            )}
          <div className="flex mt-20 w-full lg:flex-row flex-col">
            <div className="flex gap-14 mt-20 md:flex-row justify-center flex-col z-10 lg:ml-4 lg:pl-4">
              <Card image={Buildings} title1="Şehiriçi ve Şehirlerarası" title2="Dağıtım Hizmetleri" p1="Müşterilerimizin ihtiyacına göre günlük" p2="İstanbul şehir içine birden fazla noktaya dağıtım..." />
              <Card image={Piston} title1="Otomotik Yedek Parça" title2="Dağıtım Hizmetleri" p1="Hizmet verdiğimiz firmalarından teslim aldığımız ürünler herhangi bir aktarma..." p2="" />
              <Card image={GasolineBarrel} title1="Tehlikeli Madde" title2="Taşımacılığı - ADR" p1="Müşterilerimizin ihtiyacına göre günlük" p2="İstanbul şehir içine birden fazla noktaya dağıtım..." />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
