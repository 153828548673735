import React from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ContactCard from "../components/ContactCard";
import Phone from "../assets/svg/phone.png";
import Mail from "../assets/svg/mail.png";
import Location from "../assets/svg/location.png";
import Seo from "../components/Seo";

export default function Home() {
  return (
    <>
      <Seo title="Contact" description="City Logistics & Courier Contact Page" />
      <Navbar />
      <div className="relative w-full h-96 mt-5">
        <iframe className="absolute top-0 left-0 w-full h-full"
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12065.620087760686!2d29.4147758!3d40.8849296!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cad81f584ef597%3A0xa7c0991e54b07f10!2sCity%20Logistics%20%26%20Courier%20Ta%C5%9F%C4%B1mac%C4%B1l%C4%B1k%20Hiz.%20Tic.%20A.%C5%9E.%20(City%20Lojistik)!5e0!3m2!1str!2str!4v1697551573233!5m2!1str!2str"
          aria-hidden="false">
        </iframe>
      </div>
      <div className="py-24 md:px-60">
        {/* Page Info */}
        <div className="xs:px-10">
          <p className="font-medium lg:text-5xl text-[#09244D] md:text-3xl xs:text-xl">
            City Lojistik
            <span className="inline-block lg:w-20 h-1 lg:ml-5 lg:mt-0 xs:ml-5 pt-0 mb-3 top-0 rounded-lg bg-[#f4bc2f] md:w-10 md:ml-5 sm:w-5 sm:mt-5 xs:mt-5"></span>
          </p>
          <p className="font-medium lg:text-7xl text-[#09244D] pt-3 md:text-5xl sm:text-3xl xs:text-3xl">İletişim Bilgilerimiz</p>
          <p className="text-[#09244D] pt-9 text-xl xs:text-sm">Aşağıdaki seçeneklerden herhangi biriyle <br /> bizimle iletişime geçebilirsiniz!</p>
        </div>
        {/* Cards */}
        <div className="mt-6 w-full">
          <div className="flex flex-col lg:flex-row w-full items-center justify-center">
            <ContactCard image={Phone} tel="0850 259 24 89" p1="Telefon nuramızdan bize danışabilir görüş ve şikayetleriniz hakkında destek alabilirsiniz." />
            <ContactCard image={Mail} mail="info@citylojistik.com" p1="Her Türlü Görüş, Öneri ve Şikayetinizi Bize E-Posta Yoluyla Ulaştırabilirsiniz." />
          </div>
          <div className="flex flex-col lg:flex-row w-full items-center justify-center">
            <ContactCard image={Location} title="Genel Merkez" p1="City Logistics Courier Taşımacılık Hizmetleri Ticaret A.Ş. Tepeören Mah. Karadağ Cad. Stüdyo Milenium Park No:18-6/3 34959 Tuzla – İSTANBUL" />
            <ContactCard image={Location} title="Depo" p1="Telefon nuramızdan bize danışabilir görüş ve şikayetleriniz hakkında destek alabilirsiniz." />
          </div>
        </div>
        {/* Form */}
        <div className="mt-20">
          {/* Form Info */}
          <div className="xs:px-10">
            <p className="font-medium lg:text-5xl text-[#09244D] md:text-3xl xs:text-xl">
              İletişim
              <span className="inline-block lg:w-20 h-1 lg:ml-5 lg:mt-0 xs:ml-5 pt-0 mb-3 top-0 rounded-lg bg-[#f4bc2f] md:w-10 md:ml-5 sm:w-5 sm:mt-5 xs:mt-5"></span>
            </p>
            <p className="font-medium lg:text-7xl text-[#09244D] pt-3 md:text-5xl sm:text-3xl xs:text-3xl">Formu</p>
          </div>
          {/* Form */}
          <form className="mt-10 mx-10">
            <div className="flex lg:flex-row flex-col gap-5">
              <input type="text" placeholder="Adınız Soyadınız" className="basis-1/3 border-2 border-[#D4D4D4] rounded-md py-2 pl-2 pr-20" />
              <input type="text" placeholder="E-Posta Adresiniz" className="basis-1/3 border-2 border-[#D4D4D4] rounded-md py-2 pl-2 pr-20" />
              <input type="number" placeholder="Gönderi Numaranız" className="basis-1/3 border-2 border-[#D4D4D4] rounded-md py-2 pl-2 pr-20" />
            </div>
            <div className="mt-5">
              <textarea className="resize-y border-2 border-[#D4D4D4] rounded-md w-full h-44 p-2" placeholder="Mesajınız"></textarea>
            </div>
            <div className="flex flex-row-reverse">
              <a href="mailto" className="bg-[#09244D] text-white text-bold rounded-lg py-2 px-20">Mesajı Gönder</a>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </>
  );
}
