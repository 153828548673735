import React from "react";
import Forklift from "../assets/svg/forklift.png";
import Puzzle from "../assets/svg/puzzle.png";
import Rocket from "../assets/svg/rocket.png";
import TransparentCard from "../components/TransparentCard";

export default function AboutRow() {
  return (
    <>
      <div className="flex flex-col xl:flex-row lg:flex-row md:flex-row sm:flex-row bg-[#09244D] m-10 rounded-lg">
        <div className="basis-1 md:basis-1/2 sm:basis-1/2">
          <img src={Forklift} alt="" className="h-full" />
        </div>
        <div className="basis-1 md:basis-1/2 sm:basis-1/2 flex flex-col pl-14 pr-5 md:pr-40 py-32">
          <div>
            <p className="font-medium lg:text-5xl text-white md:text-3xl sm:text-xl xs:text-xl">
              City Lojistik
              <span className="inline-block lg:w-20 h-1 lg:ml-5 lg:mt-0 pt-0 mb-3 top-0 rounded-lg bg-[#f4bc2f] md:w-10 md:ml-5 sm:w-5 sm:mt-5"></span>
            </p>
            <p className="font-medium lg:text-7xl text-white pt-3 md:text-5xl sm:text-3xl xs:text-3xl">Hakkımızda</p>
            <p className="text-white pt-9">City Logistics & Courier Taşımacılık Hizmetleri A.Ş. Ağustos 1999 yılında kurularak Lojistik faaliyetlerine başlamıştır.</p>
            <p className="text-white pt-6">O dönemde, hızla gelişen müşteri ihtiyaçları paralelinde depolama, elleçleme, Şehiriçi ve şehirdışı, Gece/Gündüz Rut bazlı teslimatlar, Komple ve Parsiyel Karayolu nakliye hizmetleri, Masadan masaya hızlı kara ve hayayolu taşımacılık hizmetleri dahilinde hizmet verilmeye başlanmıştır.</p>
          </div>
          <div className="flex flex-col lg:flex-row">
            <TransparentCard image={Puzzle} title1="Misyonumuz" title2="" p1="Müşterilerimizin ihtiyacına göre günlük İstanbul şehir içine birden fazla noktaya dağıtım..." p2="" />
            <TransparentCard image={Rocket} title1="Vizyonumuz" title2="" p1="Müşterilerimizin ihtiyacına göre günlük İstanbul şehir içine birden fazla noktaya dağıtım..." p2="" />
          </div>
        </div>
      </div>
    </>
  );
}
